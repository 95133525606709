<template>
  <div class='page-box'>
    <base-screen class="custom-screen">
      <a-form-model ref="formRef" layout="inline" :model="queryRef" @submit="handleSearch" @submit.native.prevent>
        <a-form-model-item label="登录账号" prop="loginName">
          <a-input v-model="queryRef.loginName" placeholder="请输入" />
        </a-form-model-item>
        <a-form-model-item label="手机号" prop="phone">
          <a-input v-model="queryRef.phone" placeholder="请输入" />
        </a-form-model-item>
        <a-form-model-item label="用户编号" prop="userNo">
          <a-input v-model="queryRef.userNo" placeholder="请输入" />
        </a-form-model-item>
        <a-form-model-item class="screen-btns">
          <a-button type="primary" @click="handleSearch">查询</a-button>
          <a-button @click="handleReset">重置</a-button>
        </a-form-model-item>
      </a-form-model>
    </base-screen>
    <div class="btn-box">
      <a-button type="primary" @click="exportFn" :loading="exporting"> 导出 </a-button>
    </div>
    <base-table :columns="columns" :dataSource="dataRef" :pagination="pageRtv" :rowKey="record => record.userId" :loading="loadingRef" @change="handleTableChange">
    </base-table>
  </div>
</template>

<script>
import { onMounted, ref, watch } from '@vue/composition-api'
import moment from 'moment'
import { agent } from '@/api'
const columns = [
  {
    title: '登录账户',
    dataIndex: 'loginName',
  },
  {
    title: '手机号',
    dataIndex: 'phone',
  },
  {
    title: '用户编号',
    dataIndex: 'userNo',
  },
  {
    title: '证件号码',
    dataIndex: 'idcardCode',
  },
  {
    title: '注册时间',
    dataIndex: 'createTime',
  },
]

export function downloadFile (fetcher, defaultFileName = '下载_' + Date.now()) {
  return fetcher().then((res) => {
    console.log(res)
    const { headers, data: blob } = res
    const m = /filename=("|')?(.+)\1($|\s|;)/.exec(headers['content-disposition'])
    const serverSuggestName = m && m[2] ? decodeURIComponent(m[2]) : defaultFileName

    // IE & 旧版本Edge
    if ('msSaveOrOpenBlob' in navigator) {
      navigator.msSaveOrOpenBlob(blob, serverSuggestName)
    } else {
      const el = document.createElement('a')
      const url = URL.createObjectURL(blob)
      el.setAttribute('href', url)
      el.setAttribute('download', serverSuggestName)
      el.style.display = 'none'
      document.body.append(el)
      el.click()
      setTimeout(() => {
        URL.revokeObjectURL(url)
        el.remove()
      })
    }
  })
}

export function useExport (fetcher, defaultFileName) {
  const exporting = ref(false)
  async function exportFn () {
    exporting.value = true
    await downloadFile(fetcher, defaultFileName)
    exporting.value = false
  }
  return {
    exporting,
    exportFn
  }
}
export default {
  setup (props, { root }) {
    const formRef = ref(null)
    const dateRange = ref([])
    watch(
      () => dateRange.value,
      (data) => {
        if (data.length) {
          queryRef.value.startTime = `${moment(dateRange.value[0]).format('YYYY-MM-DD')} 00:00:00`
          queryRef.value.endTime = `${moment(dateRange.value[1]).format('YYYY-MM-DD')} 23:59:59`
        } else {
          queryRef.value.startTime = ''
          queryRef.value.endTime = ''
        }
      }
    )

    const queryRef = ref({
      loginName: '',
      phone: '',
      userNo: '',
    })
    const dataRef = ref([])
    const pageRtv = ref({
      current: 1,
      size: 10,
      total: 0,
    })
    const levels = ref([])
    const loadingRef = ref(false)
    onMounted(() => {
      Promise.all([getLevelData(), getTableData()])
    })
    async function getTableData () {
      loadingRef.value = true
      dataRef.value = []
      const { data, page } = await agent.getUserCardPage({ ...queryRef.value, ...pageRtv.value })
      loadingRef.value = false

      dataRef.value = data || []
      pageRtv.value.total = page.total
    }

    function handleSearch () {
      pageRtv.value.current = 1
      getTableData()
    }

    async function handleReset () {
      formRef.value.resetFields()
      dateRange.value = []
      await root.$nextTick()
      handleSearch()
    }

    function handleTableChange ({ current }) {
      pageRtv.value.current = current
      getTableData()
    }

    function handleDateShortcut (day) {
      dateRange.value = [
        moment().subtract(day, 'd'),
        day === 1 ? moment().subtract(1, 'd') : moment(),
      ]
    }
    async function getLevelData () {
      const { data, msg, code } = await agent.getLevelData()
      if (code === '00000') {
        levels.value = data
      } else {
        root.$message.error(msg || '获取数据失败')
      }
    }
    async function passWithdraw (record) {
      root.$confirm({
        title: '操作提示',
        content: '确认通过此条提现申请？',
        onOk: async () => {
          const { code, msg } = await agent.passWithdraw({
            sharerWithdrawApplyId: record.sharerWithdrawApplyId
          })
          if (code === '00000') {
            root.$message.success('操作成功')
            getTableData()
          } else {
            root.$message.error(msg || '操作失败')
          }
        }
      })
    }

    const { exportFn, exporting } = useExport(() => agent.exportCardPic(queryRef.value))

    return {
      queryRef,
      formRef,
      dateRange,
      handleDateShortcut,
      handleTableChange,
      handleReset,
      handleSearch,
      columns,
      levels,
      loadingRef,
      dataRef,
      pageRtv,
      passWithdraw,
      exportFn,
      exporting
    }
  },
}
</script>

<style lang="less" scoped>
@label-width: 84px;
.custom-screen {
  ::v-deep .solt-div {
    padding: 10px 0 0;
  }
  ::v-deep .ant-form-item-label {
    width: @label-width;
  }
}
.screen-btns {
  margin-left: @label-width;
}
.btn-date-shortcut {
  margin: 4px 12px 0 0;
  &:last-child {
    margin-right: 0;
  }
}
.record-list {
  margin-bottom: 32px;
  .record-item {
    margin-bottom: 8px;
    &:last-child {
      margin: 0;
    }
  }
}
</style>
